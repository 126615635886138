/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 BoosterPack.glb 
*/


import React, { useEffect, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three' 

export function Model({cover, isOpen, ...props}) {
  const group = React.useRef()
  const { nodes, materials, animations } = useGLTF(`${process.env.PUBLIC_URL}/BoosterPack.glb`);
  useGLTF.preload(`${process.env.PUBLIC_URL}/BoosterPack.glb`);
  const { actions } = useAnimations(animations, group)
  const glowMaterial = materials['Glow']
  const [currentCover, setCurrentCover] = useState(cover)
  
  const resetPack = () => {
    Object.values(actions).forEach((action) => {
      action.stop();
      action.reset();
    });

    setCurrentCover(null);  
    setTimeout(() => {
      setCurrentCover(cover);  
    }, 50); 
  }

  useEffect(() => {
    resetPack();
  }, [cover]); 

  useEffect(() => {
    if (isOpen) {  
      Object.values(actions).forEach((action) => {
        action.play();
        action.setLoop(THREE.LoopOnce, 0);
        action.clampWhenFinished = true;
      });
      
    }
  }, [isOpen, actions]); 

  useFrame(({clock}) => {
    const time = clock.getElapsedTime()

    const r = (Math.sin(time * 2) + 1) / 2 
    const g = (Math.sin(time * 2 + 2) + 1) / 2 
    const b = (Math.sin(time * 2 + 4) + 1) / 2 

    glowMaterial.emissive = new THREE.Color(r, g, b) 
    glowMaterial.emissiveIntensity = 2 
  })
  
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Parent" scale={3.257}>
          <mesh name="Glow" geometry={nodes.Glow.geometry} material={materials.Glow} position={[0, 0.819, 0.021]} scale={0} />
          <mesh name="Top" geometry={nodes.Top.geometry} material={materials.Metal} scale={[0.624, 1.004, 0.027]} />
          <mesh name="Body" geometry={nodes.Body.geometry} material={materials.Metal} scale={[0.624, 1.004, 0.027]} />
          <mesh name="ColorezzImg" geometry={nodes.ColorezzImg.geometry} material={materials.Colorezz} position={[0, 0.402, 0]} rotation={[1.55, 0, 0]} scale={0.307} />
          {currentCover && materials[currentCover] && (
            <mesh
              name="Cover"
              geometry={nodes.Cover.geometry}
              material={materials[currentCover]}
              position={[0, 0, 0.034]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[0.479, 0.479, 0.704]}
            />
          )}
          <mesh name="SelvaImg" geometry={nodes.SelvaImg.geometry} material={materials.Selva} position={[0, -0.465, 0]} rotation={[1.55, 0, 0]} scale={0.307} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/BoosterPack.glb')
